import { Code } from "../../components/Code";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Column } from "../../components/lib";
import { assertPiSdkErrorShape } from "../../utils";
import { useState } from "react";

const SdkNative = () => {
  const [url, setUrl] = useState('https://example.com')

  const [nativeFeaturesListRes, setNativeFeaturesListRes] = useState<Pi.SdkResponse | null>(null);
  const [requestPermissionRes, setRequestPermissionRes] = useState<Pi.SdkResponse | null>(null);
  const [copyTextRes, setCopyTextRes] = useState<Pi.SdkResponse | null>(null);
  const [openUrlInSystemBrowserRes, setOpenUrlInSystemBrowserRes] = useState<Pi.SdkResponse | null>(null);
  const [openShareDialogRes, setOpenShareDialogRes] = useState<Pi.SdkResponse | null>(null);
  const [checkUserHasMiningApp, setCheckUserHasMiningApp] = useState<Pi.SdkResponse | null>(null);
  const [checkUserHasPiBrowser, setCheckUserHasPiBrowser] = useState<Pi.SdkResponse | null>(null);

  const nativeFeaturesList = async () => {
    try {
      const res = await Pi.nativeFeaturesList();
      setNativeFeaturesListRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setNativeFeaturesListRes({ type: "error", error: err.message });
    }
  };

  const requestPermission = async () => {
    try {
      const res = await Pi.requestPermission("camera");
      setRequestPermissionRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setRequestPermissionRes({ type: "error", error: err.message });
    }
  };

  const copyText = async () => {
    try {
      const res = await Pi.copyText("example text to be copied");
      setCopyTextRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setCopyTextRes({ type: "error", error: err.message });
    }
  };

  const openUrlInSystemBrowser = async () => {
    try {
      const res = await Pi.openUrlInSystemBrowser(url);
      setOpenUrlInSystemBrowserRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setOpenUrlInSystemBrowserRes({ type: "error", error: err.message });
    }
  };

  const openShareDialog = async () => {
    try {
      const res = await Pi.openShareDialog(
        "Share Title from Tiramisu",
        "Example share message from Tiramisu. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia ipsum culpa dolorum rem, eos placeat."
      );
      setOpenShareDialogRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setOpenShareDialogRes({ type: "error", error: err.message });
    }
  };

  const checkMiningApp = async () => {
    try {
      const res = await Pi.checkUserHasMiningApp();
      setCheckUserHasMiningApp({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setCheckUserHasMiningApp({ type: "error", error: err.message });
    }
  };

  const checkPiBrowser = async () => {
    try {
      const res = await Pi.checkUserHasPiBrowser();
      setCheckUserHasPiBrowser({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setCheckUserHasPiBrowser({ type: "error", error: err.message });
    }
  };

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Test Pi SDK Native Methods
      </Typography>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.nativeFeaturesList
        </Typography>
        <Button variant="contained" size="small" onClick={nativeFeaturesList}>
          Pi.nativeFeaturesList
        </Button>
        {nativeFeaturesListRes?.type === "error" ? (
          <Code mt={1} type="error">
            {nativeFeaturesListRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {nativeFeaturesListRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.requestPermission
        </Typography>
        <Button variant="contained" size="small" onClick={requestPermission}>
          Pi.requestPermission
        </Button>
        {requestPermissionRes?.type === "error" ? (
          <Code mt={1} type="error">
            {requestPermissionRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {requestPermissionRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.copyText
        </Typography>
        <Button variant="contained" size="small" onClick={copyText}>
          Pi.copyText
        </Button>
        {copyTextRes?.type === "error" ? (
          <Code mt={1} type="error">
            {copyTextRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {copyTextRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.openUrlInSystemBrowser
        </Typography>

        <TextField 
          value={url}
          onChange={(e) => setUrl(e.target.value)} 
          fullWidth
          size='small' 
          inputProps={{
            autoCapitalize: 'none',
            autoCorrect: 'none',
          }}
        />

        <Button variant="contained" size="small" onClick={openUrlInSystemBrowser}>
          Pi.openUrlInSystemBrowser
        </Button>
        {openUrlInSystemBrowserRes?.type === "error" ? (
          <Code mt={1} type="error">
            {openUrlInSystemBrowserRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {openUrlInSystemBrowserRes?.value}
          </Code>
        )}
      </Box>

      <Box>
        <Typography variant="h6" mb={1}>
          Pi.openShareDialog
        </Typography>
        <Button variant="contained" size="small" onClick={openShareDialog}>
          Pi.openShareDialog
        </Button>
        {openShareDialogRes?.type === "error" ? (
          <Code mt={1} type="error">
            {openShareDialogRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {openShareDialogRes?.value}
          </Code>
        )}
      </Box>
      <Box>
        <Typography variant="h6" mb={1}>
          Pi.checkUserHasMiningApp
        </Typography>
        <Button variant="contained" size="small" onClick={checkMiningApp}>
          Pi.checkUserHasMiningApp
        </Button>
        {checkUserHasMiningApp?.type === "error" ? (
          <Code mt={1} type="error">
            {checkUserHasMiningApp.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {checkUserHasMiningApp?.value}
          </Code>
        )}
      </Box>
      <Box>
        <Typography variant="h6" mb={1}>
          Pi.checkUserHasPiBrowser
        </Typography>
        <Button variant="contained" size="small" onClick={checkPiBrowser}>
          Pi.checkUserHasPiBrowser
        </Button>
        {checkUserHasPiBrowser?.type === "error" ? (
          <Code mt={1} type="error">
            {checkUserHasPiBrowser.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {checkUserHasPiBrowser?.value}
          </Code>
        )}
      </Box>
    </Column>
  );
};

export default SdkNative;
