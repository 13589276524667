import { useState } from "react";
import { assertPiSdkErrorShape } from "../../utils";
import { Code } from "../../components/Code";
import {
  Box,
  Button,
  FormControl,
  List,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import { Column } from "../../components/lib";

const SdkWallet = () => {
  const [xdr, setXdr] = useState<string>("");
  const [signTransactionRes, setSignTransactionRes] =
    useState<Pi.SdkResponse | null>(null);
  const [getUserMigratedWalletAddressRes, setGetUserMigratedWalletAddressRes] =
    useState<Pi.SdkResponse | null>(null);

  const setDummyValidXDR = () =>
    setXdr(
      "AAAAAgAAAABlAqB5MBHVZyfZq6h/p2tcwpSYtis9BFAY6XyQUyzMHQAAAGQAADr4AAABzAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAQAAABxQaSBEb21haW4gQmlkIGZvciBleGFtcGxlLnBpAAAAAQAAAAEAAAAAZQKgeTAR1Wcn2auof6drXMKUmLYrPQRQGOl8kFMszB0AAAAOAAAAAAAAAAA7msoAAAAAAgAAAAAAAAAAZQKgeTAR1Wcn2auof6drXMKUmLYrPQRQGOl8kFMszB0AAAAAAAAAAAAAAAAHPA30amrOMtr970jf7iUY05BlRz3opN8UjTT2dOoG9QAAAAMAAAABAAAABAAAAABoXzCAAAAAAAAAAAA="
    );

  const setDummyOpsDisallowedXDR = () =>
    setXdr(
      "AAAAAgAAAABlAqB5MBHVZyfZq6h/p2tcwpSYtis9BFAY6XyQUyzMHQAAAMgAADr4AAABzAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAQAAABxQaSBEb21haW4gQmlkIGZvciBleGFtcGxlLnBpAAAAAgAAAAEAAAAAZQKgeTAR1Wcn2auof6drXMKUmLYrPQRQGOl8kFMszB0AAAAOAAAAAAAAAAA7msoAAAAAAgAAAAAAAAAAZQKgeTAR1Wcn2auof6drXMKUmLYrPQRQGOl8kFMszB0AAAAAAAAAAAAAAAAHPA30amrOMtr970jf7iUY05BlRz3opN8UjTT2dOoG9QAAAAMAAAABAAAABAAAAABoXzCAAAAAAQAAAABlAqB5MBHVZyfZq6h/p2tcwpSYtis9BFAY6XyQUyzMHQAAABAAAAAABREvMBoA3BpZVOtpqbxMhgg80L1uRxCHJwbb0hJ1T3sAAAAAAAAAAA=="
    );

  const signTransaction: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    setSignTransactionRes(null);

    e.preventDefault();

    try {
      const res = await Pi.Wallet.signTransaction(xdr);
      setSignTransactionRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setSignTransactionRes({ type: "error", error: err.message });
    }
  };

  const getUserMigratedWalletAddress = async () => {
    setGetUserMigratedWalletAddressRes(null);

    try {
      const res = await Pi.Wallet.getUserMigratedWalletAddress();
      setGetUserMigratedWalletAddressRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setGetUserMigratedWalletAddressRes({
          type: "error",
          error: err.message,
        });
    }
  };

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Test Pi SDK Wallet
      </Typography>

      <Column>
        <Typography>.signTransaction(xdr)</Typography>
        <Box mb={2} component="form" onSubmit={signTransaction}>
          <Column>
            <List>
              <ListItemButton onClick={setDummyValidXDR}>
                Use dummy valid XDR
              </ListItemButton>
              <ListItemButton onClick={setDummyOpsDisallowedXDR}>
                Use dummy XDR with invalid ops
              </ListItemButton>
            </List>

            <TextField
              value={xdr}
              size="small"
              label="XDR"
              onChange={(e) => setXdr(e.target.value)}
              fullWidth
            />

            <Button type="submit" variant="contained" size="small">
              .signTransaction
            </Button>
            {signTransactionRes?.type === "error" ? (
              <Code mt={1} type="error">
                {signTransactionRes.error}
              </Code>
            ) : (
              <Code mt={1} type="success">
                {signTransactionRes?.value}
              </Code>
            )}
          </Column>
        </Box>
      </Column>

      <Column>
        <Typography>.getUserMigratedWalletAddress()</Typography>
        <Column>
          <Button
            variant="contained"
            size="small"
            onClick={getUserMigratedWalletAddress}
          >
            .getUserMigratedWalletAddress
          </Button>
          {getUserMigratedWalletAddressRes?.type === "error" ? (
            <Code mt={1} type="error">
              {getUserMigratedWalletAddressRes.error}
            </Code>
          ) : (
            <Code mt={1} type="success">
              {getUserMigratedWalletAddressRes?.value}
            </Code>
          )}
        </Column>
      </Column>
    </Column>
  );
};

export default SdkWallet;
