import { useState } from "react";
import { assertPiSdkErrorShape } from "../utils";
import { SDK_ADS_ROUTE, SDK_NATIVE_ROUTE, SDK_WALLET_ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";
import { Code } from "../components/Code";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Column } from "../components/lib";
import {
  postHandleCanceledPayment,
  postHandleIncompletePayment,
  postHandlePaymentApproval,
  postHandlePaymentCompletion,
} from "../features/payment/paymentAPI";
import { onIncompletePaymentFound } from "../features/auth/authSlice";

const SdkMethods = () => {
  const navigate = useNavigate();

  const [authenticateRes, setAuthenticateRes] = useState<Pi.SdkResponse | null>(
    null
  );
  const [openConversationRes, setOpenConversationRes] =
    useState<Pi.SdkResponse | null>(null);
  const [scanQrCodeRes, setScanQrCodeRes] = useState<Pi.SdkResponse | null>(
    null
  );
  const [qrScannerFacingMode, setQrScannerFacingMode] = useState<
    "environment" | "user"
  >("environment");
  const [getPiHostAppNameRes, setGetPiHostAppNameRes] =
    useState<Pi.SdkResponse | null>(null);
  const [getPiHostAppInfoRes, setGetPiHostAppInfoRes] =
    useState<Pi.SdkResponse | null>(null);
  const [showDownloadPiBrowserModalRes, setShowDownloadPiBrowserModalRes] =
    useState<Pi.SdkResponse | null>(null);
  const [getSafeAreaInsetsRes, setGetSafeAreaInsetsRes] =
    useState<Pi.SdkResponse | null>(null);

  const authenticate = async () => {
    try {
      const res = await Pi.authenticate(
        ["username", "payments"],
        onIncompletePaymentFound
      );
      setAuthenticateRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setAuthenticateRes({ type: "error", error: err.message });
    }
  };

  const [paymentResponses, setPaymentResponses] = useState<string[]>([]);

  const createPayment = () => {
    setPaymentResponses([]);

    const paymentData = {
      amount: 0.001,
      memo: "test memo",
      metadata: { some_property: 1 },
    };
    const paymentCallbacks = {
      onReadyForServerApproval: (paymentId: string) => {
        setPaymentResponses((prev) => [
          ...prev,
          JSON.stringify(
            {
              callback: "onReadyForServerApproval",
              response: { paymentId },
            },
            null,
            2
          ),
        ]);
        postHandlePaymentApproval(paymentId);
      },
      onReadyForServerCompletion: (paymentId: string, txId: string) => {
        setPaymentResponses((prev) => [
          ...prev,
          JSON.stringify(
            {
              callback: "onReadyForServerCompletion",
              response: { paymentId, txId },
            },
            null,
            2
          ),
        ]);
        postHandlePaymentCompletion(paymentId, txId);
      },
      onCancel: (paymentId: string) => {
        setPaymentResponses((prev) => [
          ...prev,
          JSON.stringify({
            callback: "onCancel",
            response: { paymentId },
          }),
        ]);
        postHandleCanceledPayment(paymentId);
      },
      onError: (error: Error, payment?: Pi.PaymentDTO) => {
        setPaymentResponses((prev) => [
          ...prev,
          JSON.stringify(
            {
              callback: "onError",
              response: { "error.message": error.message },
            },
            null,
            2
          ),
        ]);
      },
    };

    try {
      const res = Pi.createPayment(paymentData, paymentCallbacks);
      setPaymentResponses((prev) => [
        ...prev,
        JSON.stringify(
          {
            callback: "Pi.createPayment response",
            response: { res },
          },
          null,
          2
        ),
      ]);
    } catch (err) {
      setPaymentResponses((prev) => [
        ...prev,
        JSON.stringify(
          {
            callback: "Pi.createPayment error",
            response: { err },
          },
          null,
          2
        ),
      ]);
    }
  };

  const openConversation = async () => {
    try {
      const res = await Pi.openConversation(1);
      setOpenConversationRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setOpenConversationRes({ type: "error", error: err.message });
    }
  };

  const scanQrCode = async () => {
    try {
      const res = await Pi.scanQrCode({ facingMode: qrScannerFacingMode });
      setScanQrCodeRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setScanQrCodeRes({ type: "error", error: err.message });
    }
  };

  const getPiHostAppName = async () => {
    try {
      const res = await Pi.getPiHostAppName();
      setGetPiHostAppNameRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setGetPiHostAppNameRes({ type: "error", error: err.message });
    }
  };

  const getPiHostAppInfo = async () => {
    try {
      const res = await Pi.getPiHostAppInfo();
      setGetPiHostAppInfoRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setGetPiHostAppInfoRes({ type: "error", error: err.message });
    }
  };

  const showDownloadPiBrowserModal = async () => {
    try {
      const res = await Pi.showDownloadPiBrowserModal();
      setShowDownloadPiBrowserModalRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setShowDownloadPiBrowserModalRes({ type: "error", error: err.message });
    }
  };

  const getSafeAreaInsets = async () => {
    try {
      const res = await Pi.getSafeAreaInsets();
      setGetSafeAreaInsetsRes({
        type: "success",
        value: JSON.stringify(res, null, 2),
      });
    } catch (err) {
      assertPiSdkErrorShape(err) &&
        setGetSafeAreaInsetsRes({ type: "error", error: err.message });
    }
  };

  return (
    <Column>
      <Typography variant="h3" my={2} textAlign="center">
        Test PI SDK Methods
      </Typography>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Ads
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(SDK_ADS_ROUTE)}
        >
          Navigate to Ads Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Wallet
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(SDK_WALLET_ROUTE)}
        >
          Navigate to Wallet Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Native
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(SDK_NATIVE_ROUTE)}
        >
          Navigate to Native Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.authenticate
        </Typography>
        <Button variant="contained" size="small" onClick={authenticate}>
          Pi.authenticate
        </Button>
        {authenticateRes?.type === "error" ? (
          <Code mt={1} type="error">
            {authenticateRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {authenticateRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.createPayment
        </Typography>
        <Button variant="contained" size="small" onClick={createPayment}>
          Pi.createPayment
        </Button>
        {!!paymentResponses.length && (
          <List>
            {paymentResponses.map((response) => (
              <ListItem>
                <Code>{response}</Code>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.openConversation
        </Typography>
        <Button variant="contained" size="small" onClick={openConversation}>
          Open
        </Button>
        {openConversationRes?.type === "error" ? (
          <Code mt={1} type="error">
            {openConversationRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {openConversationRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.scanQrCode
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel id="qr-scanner-facing-mode">Age</InputLabel>
          <Select
            labelId="qr-scanner-facing-mode"
            value={qrScannerFacingMode}
            label="QR Scanner Facing Mode"
            size="small"
            onChange={(e) =>
              setQrScannerFacingMode(e.target.value as "environment" | "user")
            }
          >
            <MenuItem value="environment">Environment</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" size="small" onClick={scanQrCode}>
          Pi.scanQrCode
        </Button>
        {scanQrCodeRes?.type === "error" ? (
          <Code mt={1} type="error">
            {scanQrCodeRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {scanQrCodeRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getPiHostAppName
        </Typography>
        <Button variant="contained" size="small" onClick={getPiHostAppName}>
          Pi.getPiHostAppName
        </Button>
        {getPiHostAppNameRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getPiHostAppNameRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getPiHostAppNameRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getPiHostAppInfo
        </Typography>
        <Button variant="contained" size="small" onClick={getPiHostAppInfo}>
          Pi.getPiHostAppInfo
        </Button>
        {getPiHostAppInfoRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getPiHostAppInfoRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getPiHostAppInfoRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.showDownloadPiBrowserModal
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={showDownloadPiBrowserModal}
        >
          Pi.showDownloadPiBrowserModal
        </Button>
        {showDownloadPiBrowserModalRes?.type === "error" ? (
          <Code mt={1} type="error">
            {showDownloadPiBrowserModalRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {showDownloadPiBrowserModalRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getSafeAreaInsets
        </Typography>
        <Button variant="contained" size="small" onClick={getSafeAreaInsets}>
          Pi.getSafeAreaInsets
        </Button>
        {getSafeAreaInsetsRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getSafeAreaInsetsRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getSafeAreaInsetsRes?.value}
          </Code>
        )}
      </Box>
    </Column>
  );
};

export default SdkMethods;
